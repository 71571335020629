import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import DocPageLayout from '../components/layout/DocPageLayout';
import SecuritySection from '../components/two-col-section';
import securityBackground from '../images/bg_security.svg';

const SECURITY_PAGE_DATA = graphql`
  query SecurityPageData {
    allDataSecurityJson {
      edges {
        node {
          title
          body
        }
      }
    }
    allApplicationSecurityJson {
      edges {
        node {
          title
          body
        }
      }
    }
    allInfrastructureSecurityJson {
      edges {
        node {
          title
          body
        }
      }
    }
    allCorporateSecurityJson {
      edges {
        node {
          title
          body
        }
      }
    }
  }
`;

const SecurityPage = () => {
  const {
    allDataSecurityJson,
    allApplicationSecurityJson,
    allInfrastructureSecurityJson,
    allCorporateSecurityJson,
  } = useStaticQuery(SECURITY_PAGE_DATA);
  return (
    <DocPageLayout
      title="Security"
      subtitle="Sprintlio is built with state of the art security to protect your business, team, and data."
      backgroundSrc={securityBackground}
    >
      <SecuritySection
        sectionTitle="Data"
        leftColData={allDataSecurityJson.edges.slice(0, 3)}
        rightColData={allDataSecurityJson.edges.slice(3)}
        bg="white"
      />
      <SecuritySection
        sectionTitle="Application"
        leftColData={allApplicationSecurityJson.edges.slice(0, 4)}
        rightColData={allApplicationSecurityJson.edges.slice(4)}
        bg="veryLightGrey"
      />
      <SecuritySection
        sectionTitle="Infrastructure"
        leftColData={allInfrastructureSecurityJson.edges.slice(0, 4)}
        rightColData={allInfrastructureSecurityJson.edges.slice(4)}
        bg="white"
      />
      <SecuritySection
        sectionTitle="Corporate"
        leftColData={allCorporateSecurityJson.edges.slice(0, 1)}
        rightColData={allCorporateSecurityJson.edges.slice(1)}
        bg="veryLightGrey"
      />
    </DocPageLayout>
  );
};

export default SecurityPage;
