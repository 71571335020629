import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';

import { H2, P, Outer, Inner, Box, Flex } from './styled';

const SectionTitle = (props) => (
  <H2 textAlign="center" color="black" fontSize={24} fontWeight={700} mb={30} {...props} />
);
const SectionSubtitle = (props) => <P color="black" fontSize={16} fontWeight={600} {...props} />;

const SecuritySection = ({
  sectionTitle,
  leftColData,
  rightColData,
  bodyColor,
  subtitleColor,
  children,
  ...props
}) => (
  <Outer {...props}>
    <Inner>
      {sectionTitle && <SectionTitle>{sectionTitle}</SectionTitle>}
      {children}
      <Flex justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
        <Box width={['100%', '50%']} pr={[0, 25]}>
          {leftColData.map(({ node: { title, body } }) => (
            <Box key={`${title}-${body}`} mb={20}>
              <SectionSubtitle>{title}</SectionSubtitle>
              <P variant="body1" color={bodyColor}>
                <Markdown>{body}</Markdown>
              </P>
            </Box>
          ))}
        </Box>
        <Box width={['100%', '50%']} pl={[0, 25]}>
          {rightColData.map(({ node: { title, body } }) => (
            <Box key={`${title}-${body}`} mb={20}>
              <SectionSubtitle>{title}</SectionSubtitle>
              <P variant="body1" color={bodyColor}>
                <Markdown>{body}</Markdown>
              </P>
            </Box>
          ))}
        </Box>
      </Flex>
    </Inner>
  </Outer>
);

SecuritySection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  leftColData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rightColData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  bodyColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  children: PropTypes.node,
};

SecuritySection.defaultProps = {
  children: null,
  bodyColor: null,
  subtitleColor: null,
};

export default SecuritySection;
